@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

html {
  font-size: 62.5%;
}

body {
  font-family: 'Roboto', sans-serif;
  font-size: $m-size;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 992px) {
      width: 970px;
  }

  @media (min-width: 1200px) {
      width: 1170px;
  }

  @media (min-width: 1600px) {
      width: 1400px;
  }

  @media (min-width: 2000px) {
      width: 1600px;
  }
}

.img-responsive {
  width: 100%;
}

button {
  cursor: pointer;
}

button:disabled {
  cursor: default;
}

.is-active {
  font-weight: bold;
}
input:focus, button:focus, textarea:focus {
  outline: none;
}
.footer {
  // background-color: #A1F814;
  background: rgb(95,153,0);
  background: linear-gradient(90deg, rgba(95,153,0,1) 0%, rgba(161,248,20,1) 50%, rgba(95,153,0,1) 100%);
  padding-top: 10px;
  padding-bottom: 10px;
  color: #0D0D0D;

  &__inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__logo {
    flex: 1 1 400px;

    img{
      max-width: 150px;
    }
  }

  &__copy {
    flex: 1 1 100%;
    text-align: center;
    padding-top: 20px;

    @media screen and (min-width: 1030px) {
      flex: 1 1 400px;
      padding-top: 0;
    }
  }
}
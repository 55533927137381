.homehero {
  
  &__hero {
    overflow: hidden;
    position: relative;
    max-height: 80vh;

    .overlay {
      // background: -moz-linear-gradient(90deg, rgba(255, 255, 255, 0.1) 0%, rgba(255,255,255,0) 50%);
      // background: -webkit-linear-gradient(90deg, rgba(255, 255, 255, 0.1) 0%, rgba(255,255,255,0) 50%);
      // background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgba(255,255,255,0) 70%);
      background-color: rgba(0, 0, 0, 0.2);
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      // animation: 2s ease-in 0s 1 heroOverlay;
      // animation-delay: 0;

      // @keyframes heroOverlay {
      //   0% {
      //     transform: translateX(-100%);
      //   }
      //   100% {
      //     transform: translateX(0);
      //   }
      // }
    }

    img {
      display: block;
      width: 150%;
      vertical-align: middle;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -o-transform: translateX(-50%);
      transform: translateX(-20%);

      @media screen and (min-width: 1300px){
        width: 130%;
      }
      @media screen and (min-width: 1800px){
        transform: unset;
        width: 100%;
        left: unset;
        display: inline-block;
      }
    }

    &__welcome {
      position: absolute;
      left: 20px;
      right: 20px;
      top: 10px;
      font-size: 0.8em;
      text-align: center;
      text-transform: uppercase;
      background-color: rgba(0, 0, 0, 0.8);
      border-radius: 10px;
      padding: 20px;

      @media screen and (min-width: 370px) {
        position: absolute;
        left: 10%;
        right: 10%;
        top: 50%;
        bottom: unset;
        transform: translateY(-50%);
        padding: 40px;
      }
      @media screen and (min-width: 650px) {
        position: absolute;
        left: 20%;
        right: 20%;
        top: 50%;
        bottom: unset;
        transform: translateY(-50%);
        font-size: 1em;
        padding: 40px;
      }
      @media screen and (min-width: 900px) {
        position: absolute;
        top: 40%;
        left: 50px;
        right: unset;
        transform: translateY(-50%);
        font-size: 1.2em;
        text-align: left;
        padding: 40px;
      }
      @media screen and (min-width: 1250px) {
        position: absolute;
        top: 30%;
        transform: translateY(-50%);
        left: 100px;
        color: white;
        font-size: 1.2em;
        text-align: left;
        padding: 40px;
      }

      @media screen and (min-width: 2200px) {
        position: absolute;
        top: 30%;
        transform: translateY(-50%);
        left: 200px;
        color: white;
        font-size: 2em;
        text-align: left;
        padding: 50px;
      }

      @media screen and (min-width: 2350px) {
        position: absolute;
        top: 30%;
        transform: translateY(-50%);
        left: 200px;
        color: white;
        font-size: 2em;
        text-align: left;
        padding: 50px;
      }
      
      // animation: 1s ease-in 1s 1 welcomeFadeIn;
      // opacity: 0;
      // animation-fill-mode: forwards;

      
      h1 {
        font-weight: 400;
        line-height: 0.9em;
        margin: 0;
        color: #A1F814;
        padding-bottom: 20px;
      }

      p {
        color: white;
        font-weight: 400;
        text-transform: none;
        font-size: 0.9em;
        letter-spacing: 0.5px;
        line-height: 1.2em;
        margin: 5px 0;

        @media screen and (min-width: 900px) {
          font-size: 0.8em;
        }

        .free {
          color: #A1F814;
          text-transform: uppercase;
        }
      }

      .req-quote {
        margin: 30px auto 0 auto;
        width: 200px;
        border: 1px solid #A1F814;
        display: block;

        @media screen and (min-width: 500px) {
          margin: 30px auto 0 auto;
          width: 300px;
          border: 1px solid #A1F814;
          display: block;
        }
        @media screen and (min-width: 900px) {
          display: block;
          border: 1px solid #A1F814;
          width: 300px;
          text-align: center;
        }

        a {
          text-decoration: none;
          max-width: 200px;
        }

        p {
          padding: 20px 40px;
          margin: 0;

          &:hover {
            background-color: rgba(255, 255, 255, 0.2);
          }
        }
      }

      // @keyframes welcomeFadeIn {
      //   0% {
      //     opacity: 0;
      //   }
      //   100% {
      //     opacity: 1;
      //   }
      // }
    }
  }
}
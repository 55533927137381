.contact {
  // background-color: #0D0D0D;
  background: rgb(34,34,34);
  background: radial-gradient(circle, rgba(34,34,34,1) 0%, rgba(0,0,0,1) 100%);
  padding: 20px 20px 60px 20px;
  color: white;
  border-top: 1px solid #A1F814;
  border-bottom: 1px solid #A1F814;
  
  @media screen and (min-width: 600px) {
    padding: 0 0 60px 0;
  }

  &__title {
    text-align: center;
    font-size: 1.2em;

    @media screen and (min-width: 600px) {
      font-size: 1.5em;
    }

    h2 {
      display: inline-block;
      color: #A1F814;
      font-weight: 400;
      text-transform: uppercase;
    }
  }

  &__form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 800px;
    margin: 0 auto;
    position: relative;

    &__group {
      flex: 1 1 100%;
      padding: 10px 0;
      color: white;

      @media screen and (min-width: 600px) {
        flex: 1 1 40%;
        padding: 10px;
        color: white;
      }

      input {
        display: inline-block;
        padding: 10px 5px;
        width: 96%;
        background: transparent;
        border: 1px solid white;
        color: inherit;

        @media screen and (min-width: 600px) {
          width: 96%;
        }
      }

      label {
        display: block;
        text-align: left;
        padding-bottom: 5px;
      }

      textarea {
        width: 96%;
        height: 200px;
        resize: none;
        display: inline-block;
        padding: 10px 5px;
        background: transparent;
        border: 1px solid white;
        color: inherit;

        @media screen and (min-width: 600px) {
          width: 98%;
        }
      }

      .success_message {
        text-align: center;
        color: $primary;
      }
    }

    &__submit {
      flex: 1 1 100%;
      padding: 15px 0;
      color: white;
      text-align: center;
      display: inline-block;
      
      @media screen and (min-width: 500px) {
        text-align: right;
        padding: 15px;
      }

      .submit {
        padding: 20px 60px;
        background-color: transparent;
        border: 1px solid #A1F814;
        color: inherit;
        width: 100%;
        margin-top: 30px;

        @media screen and (min-width: 500px) {
          width: unset;
        }

        &:hover {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }
    }

    .form_error {
      flex: 1 1 100%;
      text-align: center;
      color: red;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 75px;
      z-index: 4;
    }
  }
}
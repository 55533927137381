.about {
  // background-color: #0D0D0D;
  background: rgb(34,34,34);
  background: radial-gradient(circle, rgba(34,34,34,1) 0%, rgba(0,0,0,1) 100%);
  padding: 5px;
  color: white;
  border-top: 1px solid #A1F814;
  border-bottom: 1px solid #A1F814;

  @media screen and (min-width: 600px) {
    padding: 20px 20px 40px 20px;
  }

  &__title {
    text-align: center;
    font-size: 1.2em;

    @media screen and (min-width: 600px) {
      font-size: 1.5em;
    }

    h2 {
      display: inline-block;
      color: #A1F814;
      font-weight: 400;
      text-transform: uppercase;
     
      // &:after {
      //   content: '';
      //   width: 100%;
      //   height: 2px;
      //   display: block;
      //   background: #A1F814;
      //   transition: 300ms;
      // }
    }
  }

  &__content {
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
  }
}
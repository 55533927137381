.main-menu {
  flex: 1 1 300px;

  @media screen and (min-width: 1000px) {
    flex: 1 1 500px;
  }

  &__link {
    padding: 10px;
    color: inherit;
    text-decoration: none;
    transition: border-bottom 0.4s;
    display: inline-block;
    text-transform: uppercase;
    font-size: 0.9em;

    @media screen and (min-width: 1000px) {
      padding: 10px 30px;
    }

    &:after {
      content: '';
      width: 0px;
      height: 2px;
      display: block;
      background: #A2F02B;
      transition: 300ms;
    }

    &:hover:after {
      width: 100%;
    }

    &.is-active:after {
      width: 100%;
    }
  }

  &.header-menu {
    display: none;

    @media screen and (min-width: 750px) {
        display: block;
    }
  }
}


.footer-menu .main-menu {
  flex: 1 1 100%;
  text-align: center;

  @media screen and (min-width: 1030px) {
    flex: 1 1 500px;
    text-align: center;
  }

  &__link {
    padding: 10px 20px;
    color: inherit;
    text-decoration: none;
    transition: border-bottom 0.4s;
    display: block;
    text-align: center;

    @media screen and (min-width: 1030px) {
      padding: 10px 30px;
      color: inherit;
      text-decoration: none;
      transition: border-bottom 0.4s;
      display: inline-block;
      text-transform: uppercase;
    }

    &:after {
      content: '';
      width: 0px;
      height: 2px;
      display: block;
      background: black;
      transition: 300ms;
    }

    &:hover:after {
      width: 100%;
    }
    &.is-active:after {
      width: 100%;
    }
    &.header {
      display: none;
  
      @media screen and (min-width: 750px) {
          display: block;
      }
    }
  }
}

.mobile-menu {
  text-align: center;

  &.open {
    background-color: rgba(0, 0, 0, 0.9);
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    transition: all 0.5s ease-in-out;
  }

  &__inner {
    width: 30px;
    height: 40px;
    position: absolute;
    top: 30px;
    right: 20px;
    z-index: 4;

    @media screen and (min-width: 750px) {
      display: none;
    }

    &__line-one {
      background-color: $primary;
      height: 5px;
      width: 100%;
      border-radius: 10px;
      margin: 3px 0;
      transition: all 0.5s ease-out;

      &.open {
        transform: rotate(45deg);
        position: absolute;
        left: 0;
        top: 10px;
      }
    }
    &__line-two {
      background-color: $primary;
      width: 100%;
      height: 5px;
      border-radius: 10px;
      margin: 3px 0;

      &.open {
        visibility: hidden;
      }
    }
    &__line-three {
      background-color: $primary;
      width: 100%;
      height: 5px;
      border-radius: 10px;
      margin: 3px 0;
      transition: all 0.5s ease-out;

      &.open {
        transform: rotate(-45deg);
        position: absolute;
        right: 0;
        top: 10px;
      }
    }
  }
  
  &__content {
    display: none;
    
    &.open {
      display: block;
      transition: all 0.5s ease-in-out;
    }

    &__link {
      display: block;
      font-weight: 400;
      text-transform: uppercase;
      text-decoration: none;
      color: $primary;
      padding: 10px;
      font-size: 2rem;
      max-width: 200px;
      margin: 0 auto;
    }
  }

  &__logo {
    max-width: 200px;
    margin: 0 auto;
    padding-top: 30px;
  }
}

.header {
  // background-color: #0D0D0D;
  background: rgb(41,40,40);
  background: radial-gradient(circle, rgba(41,40,40,1) 0%, rgba(0,0,0,1) 100%);
  padding-top: 5px;
  padding-bottom: 5px;
  z-index: 2;
  color: white;

  &__inner {
    display: flex;
    align-items: center;
  }

  &__logo {
    flex: 1 1 100%;
    text-align: center;

    @media screen and (min-width: 750px) {
        flex: 1 1 200px;
        text-align: left;
    }
    @media screen and (min-width: 1300px) {
        flex: 1 1 500px;
        text-align: left;
    }

    img{
      max-width: 120px;

      @media screen and (min-width: 2300px) {
        max-width: 170px;        
      }
    }
  }
}
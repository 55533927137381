.gallery {
  // background-color: #A1F814;
  background: rgb(95,153,0);
  background: linear-gradient(90deg, rgba(95,153,0,1) 0%, rgba(161,248,20,1) 50%, rgba(95,153,0,1) 100%);
  padding-top: 10px;
  padding-bottom: 10px;
  color: #0D0D0D;

  &__title {
    text-align: center;
    font-size: 1.2em;

    @media screen and (min-width: 600px) {
      font-size: 1.5em;
    }

    h2 {
      display: inline-block;
      color: #0D0D0D;
      font-weight: 400;
      text-transform: uppercase;
     
      // &:after {
      //   content: '';
      //   width: 100%;
      //   height: 2px;
      //   display: block;
      //   background: #A1F814;
      //   transition: 300ms;
      // }
    }
  }

  &__grid {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 40px;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;

    &__group {
      flex: 1 1 250px;

      .gallery-group-inner {
        max-width: 250px;
        height: 250px;
        background-color: black;
        margin: 10px auto;
        overflow: hidden;
        position: relative;
      }

      .gallery-img {
        max-width: 240px;
        border: 5px solid black;
      }
    }
  }
}